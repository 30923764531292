// section padding
@mixin section-padding($padding-xs, $padding-sm) {
  padding-top: $padding-xs;
  padding-bottom: $padding-xs;
  @include media("≥col-sm") {
    padding-top: $padding-sm;
    padding-bottom: $padding-sm;
  }
}

// top padding
@mixin padding-top($padding-xs, $padding-sm) {
  padding-top: $padding-xs;
  @include media("≥col-sm") {
    padding-top: $padding-sm;
  }
}

// Bottom padding
@mixin padding-bottom($padding-xs, $padding-sm) {
  padding-bottom: $padding-xs;
  @include media("≥col-sm") {
    padding-bottom: $padding-sm;
  }
}

// section margins
@mixin section-margin($margin-xs, $margin-sm) {
  margin-top: $margin-xs;
  margin-bottom: $margin-xs;
  @include media("≥col-sm") {
    margin-top: $margin-sm;
    margin-bottom: $margin-sm;
  }
}
