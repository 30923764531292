a.btn,
span.btn,
button.btn {
  font-family: $headings-font-family;
  font-size: 1.25em;
  padding: 0.4em 1.5em 0.25em;
  transition: all 300ms ease;
  + a.btn {
    @include media("<col-sm") {
      margin-top: 1em;
    }
    @include media("≥col-sm") {
      margin-left: 1em;
    }
  }
  &:active {
    box-shadow: none;
  }
  &-block {
    display: block;
    + a.btn-block {
      margin-top: 0.5em;
      margin-left: 0;
    }
  }
  &-wrap {
    white-space: normal;
  }
}

.btn {
  &-secondary {
    color: $brand-tertiary;
    background-color: white;
    border-color: white;
    &:hover,
    &:focus {
      color: white;
      background-color: $brand-tertiary;
      border-color: $brand-tertiary;
    }
  }
}
p {
  + .buttons {
    margin-top: 1em;
    @include media("≥col-md") {
      margin-top: 2em;
    }
  }
}

// google map
#map {
  width: 100%;
  height: calc(100vh * 0.5);
  @include media("≥col-md") {
    height: calc(100vw * 0.5);
  }
  @include media("≥col-md") {
    height: calc(100vw * 0.333);
  }
  width: 100%;
}

// Navigation
.main-nav {
  &__wrap {
    background-color: rgba(0,183,211,0.9);
  }
  &__link {
    font-family: $headings-font-family;
    font-size: 18px;
    a {
      color: white;
      transition: color 300ms ease;
      text-transform: lowercase;
    }
  }
  .nav {
    >li.active a {
      color: $brand-secondary;
      background-color: transparent;
    }
    >li>a {
      &:hover,
      &:focus {
        color: $brand-secondary;
        background-color: transparent;
      }
    }
  }
}

.navbar-brand__logo {
  width: 158px;
  @include media("≥col-md") {
    width: 235px;
  }
}

.text-center-md {
  @include media("≥col-md") {
    text-align: center;
  }
}

.general {
  @include section-padding(2em,3.75em);
  p {
    line-height: 1.5;
    &:not(:last-child) {
      margin-bottom: 1em;
    }
  }
  &__heading {
    font-size: 2em;
    color: $brand-primary;
    margin-bottom: 0.75em;
    text-transform: lowercase;
    @include media("≥col-sm") {
      font-size: 3em;
    }
    &--large {
      font-size: 3em;
      @include media("≥col-sm") {
        font-size: 4.375em;
      }
    }
  }
  &__subheading {
    color: $brand-primary;
    margin-top: 5rem;
    margin-bottom: 1.6rem;
  }
  &__icon {
    font-size: 2em;
    color: $brand-primary;
    margin-top: 1em;
    &--mb {
      @include media("<col-sm") {
        margin-bottom: 1em;
      }
    }
  }
  &__tel {
    color: $brand-quaternary;
    font-family: $headings-font-family;
    margin-bottom: 2em;
    &-intro {
      font-size: 1.625em;
    }
    &-number {
      color: $brand-quaternary;
      font-size: 2.75em;
      line-height: 1.1;
      &:hover,
      &:focus {
        text-decoration: none;
        color: darken($brand-quaternary, 10%);
      }
    }
  }
  &__side-buttons {
    margin-bottom: 2em;
  }
  &__our-dogs {
    padding-top: 0;
  }
  &__dog-information {
    margin-bottom: 0;
    list-style: none;
    li {
      line-height: 1.5;
      margin-bottom: 1em;
    }
  }
  &__nofeature {
    @include padding-top(5em,7.5em);
    @include padding-bottom(2em,3.75em);
  }
  &__social {
    margin-top: 3em;
    &-heading {
      color: $brand-primary;
    }
    &-icon {
      i {
        color: $brand-secondary;
        font-size: 1.5em;
        float: left;
        margin-right: 10px;
      }
      &:hover,
      &:focus {
        text-decoration: none;
        color: $brand-primary;
      }
    }
    a {
      color: $gray;
    }
    li {
      &:not(:first-child) {
        margin-top: 1em;
      }
    }
  }
  &__story {
    padding-top: 0;
  }
}

.cta-panel {
  background-color: $brand-quaternary;
  border-radius: 5px;
  color: white;
  padding: 15px;
  text-align: center;
  &__heading {
    font-size: 1.625em;
  }
}


.dog {
  display: flex;
  flex-wrap: wrap;
  margin-left: 15px;
  margin-right: 15px;
  @include media("≥col-md") {
    margin-left: -15px;
    margin-right: -15px;
  }
  &__item {
    margin-bottom: 2em;
    @include media("≥col-sm") {
      width: calc(50% - 30px);
      margin-left: 15px;
      margin-right: 15px;
      margin-bottom: 3em;
    }
    @include media("≥col-md") {
      width: calc(25% - 30px);
    }
  }
  &__content {
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
  &__image {
    img {
      border-radius: 50%;
      border: 5px solid $gray-lighter;
    }
  }
  &__text {
    margin-top: 1em;
  }
  &__heading {
    text-transform: lowercase;
  }
  &__excerpt {
    + .button {
      margin-top: 1.5em;
    }
  }
}

.pagination-numbers {
  border-top: 1px solid $gray-lighter;
}
// hamburger menu
.hamburger {
  border-radius: 0;
  .line {
    width: 16px;
    height: 2px;
    background-color: white;
    display: block;
    margin: 3px auto;
    transition: all 0.3s ease-in-out;
    &.is-active {
      background-color: white;
    }
  }
  &:hover {
    cursor: pointer;
  }
}

#navbarTrigger.is-active .line:nth-child(2){
opacity: 0;
}

#navbarTrigger.is-active .line:nth-child(1){
transform: translateY(6px) rotate(45deg);
}

#navbarTrigger.is-active .line:nth-child(3){
transform: translateY(-4px) rotate(-45deg);
}


// footer
.footer {
  background-color: $brand-primary;
  color: white;
  &__wrap {
    padding-top: 1.875em;
    padding-bottom: 1.875em;
    @include media("≥col-md") {
      display: flex;
      flex-wrap: wrap;
    }
  }
  &__logo {
    margin-bottom: 1.5625em;
    margin-right: auto;
    padding-right: 2em;
    &-img {
      width: 235px;
      @include media("≥col-md") {
        width: 320px;
      }
    }
    a {
      display: block;
    }
  }
  ul {
    padding: 0;
    margin-bottom: 0;
    list-style: none;
    &:first-of-type {
      h2 {
        @include media("≥col-md") {
          margin-top: 0;
        }
      }
    }
  }
  li {
    line-height: 1.4;
  }
  a {
    color: white;
    font-size: 14px;
  }
  h2 {
    font-size: 18px;
    margin-bottom: 0;
    margin-top: 1.25em;
    @include media("≥col-md") {
      margin-top: 0;
    }
  }
  &__links {
    // margin-left: auto;
    @include media("≥col-md") {
      display: flex;
    }
    > ul {
      &:not(:first-child) {
        @include media("≥col-md") {
          margin-left: 4em;
        }
      }
    }
  }
  &__social {
    float: left;
    margin-top: 0.625em;
    &:not(:nth-child(-n+2)) {
      margin-left: 1.5rem;
    }
    a {
      font-size: 30px;
      text-decoration: none;
      transition: color 300ms ease;
      &:hover,
      &:focus {
        color: $brand-secondary;
      }
    }
  }
  .backtotop {
    font-family: $headings-font-family;
    font-size: 0.875em;
    margin-top: 2em;
    @include media("≥col-md") {
      margin-top: 0;
      margin-left: 4em;
    }
  }
  &__signoff {
    background-color: $brand-secondary;
    padding-top: 0.875em;
    padding-bottom: 0.875em;
    font-size: 12px;
    a {
      font-size: 12px;
    }
    &-wrap {
      li {
        line-height: 1.6;
      }
      @include media("≥col-md") {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

.featured-image {
  position: relative;
  color: white;
  text-align: center;
  &__content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    padding-top: 60px;
    @include media("≥col-sm") {
      display: flex;
      align-items: center;
    }
    &-wrap {
      @include media("<col-sm") {
        bottom: 20px;
        left: 15px;
        right: 15px;
        position: absolute;
      }
      @include media("≥col-sm") {
        margin-left: auto;
        margin-right: 10%;
        max-width: 40%;
      }
    }
  }
  &__icon {
    font-size: 2em;
  }
  &__heading {
    font-family: $headings-font-family;
    font-size: 2.75em;
    line-height: 1.1;
    margin-top: 0.25em;
    margin-bottom: 0;
    @include media("≥col-md") {
      font-size: 4em;
    }
  }
}

.paws {
  @include section-padding(2em,4em);
  color: white;
  text-align: center;
  &--primary {
    background-color: $brand-secondary;
  }
  &--secondary {
    background-color: $brand-secondary;
  }
  &--tertiary {
    background-color: $brand-tertiary;
  }
  &--quaternary {
    background-color: $brand-quaternary;
  }
  a {
    color: white;
  }
  &__heading {
    font-size: 3em;
    color: white;
    margin-bottom: 0.75em;
    text-transform: lowercase;
  }
  .pagination-numbers {
    border-top: 1px solid rgba(255,255,255,0.5);
  }
}

.background-image {
  background-position: center center;
}

.cta-blocks {
  @include section-padding(2em,4em);
}

.cta-block {
  background-color: $brand-quaternary;
  border-radius: 8px;
  color: white;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 15px;
  margin-right: 15px;
  padding: 1.5em 1em;
  text-align: center;
  @include media("<col-md") {
    margin-bottom: 1em;
  }
  @include media("≥col-md") {
    width: calc(50% - 30px);
  }
  @include media("≥col-md", "<col-lg") {
    &:not(:nth-of-type(+n+3)) {
      margin-bottom: 2em;
    }
  }
  @include media("≥col-lg") {
    width: calc(25% - 30px);
  }
  &__wrap {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
  }
  &__text {
    margin-bottom: 1.5em;
  }
  &__button {
    margin-top: auto;
  }
}

.info-blocks {
  @include section-padding(2em,4em);
}

.info-block {
  display: flex;
  flex-direction: column;
  min-height: 1px;
  margin-left: 15px;
  margin-right: 15px;
  @include media("≥col-sm") {
    width: calc(50% - 30px);
  }
  @include media("<col-sm") {
    &:first-child {
      margin-bottom: 2em;
    }
  }
  &__wrap {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
  }
  &__image {
    border: 5px solid $gray-lighter;
    border-radius: 5px;
  }
  &__heading {
    color: $brand-primary;
    margin-bottom: 0.75em;
  }
  &__text {
    margin-top: 1.5em;
    margin-bottom: 1em;
  }
  &__button {
    margin-top: auto;
  }
}

.info-quote {
  &__text {
    color: $brand-secondary;
    font-family: $headings-font-family;
    font-size: 1.75em;
    text-align: center;
    margin-bottom: 0;
  }
}

.divider {
  hr {
    margin-top: 0;
    margin-bottom: 0;
    border: 0;
    border-top: 1px solid #eee;
  }
}

.sidebar {
  &__image {
    border: 5px solid $gray-lighter;
    border-radius: 5px;
    &-wrap {
      &:not(:first-child) {
        margin-top: 1em;
      }
    }
  }
}

.form-control {
  border-radius: 0;
  box-shadow: none;
  height: 40px;
}

.form-group {
  margin-bottom: 1.25em;
}

.help-block {
  font-size: 12px;
  margin-top: 1.5em;
}

.form--register {
  margin-top: 3em;
}

.submit-notification {
  &__wrap {
    background-color: $brand-secondary;
    padding: 2em;
    border-radius: 8px;
    margin-top: 2em;
  }
  font-family: $headings-font-family;
  font-size: 1.5em;
  color: white;
  margin-bottom: 0;
}

.dog-icons {
  color: $brand-primary;
  width: 35px;
  display: inline-block;
  text-align: center;
  margin-right: 15px;
  i {
    font-size: 1.25em;
    vertical-align: sub;
  }
}

.dog-information {
  &__heading {
    color: $brand-primary;
    padding-bottom: 5px;
    border-bottom: 1px solid $gray-lighter;
    margin-bottom: 1em;
    @include media("≥col-sm") {
      width: calc(100% - 30px);
    }
  }
}

.story {
  @include section-padding(2em,4em);
  background-image:url('../assets/img/paw-pattern-secondary.png');
  color: white;
  &__heading {
    font-size: 3em;
    margin-bottom: 0.75em;
  }
  &__image {
    border: 3px solid $gray-lighter;
    border-radius: 5px;
    @include media("<col-sm") {
      margin-bottom: 2em;
    }
  }
  &__content {
    &:not(:last-child) {
      border-bottom: 1px solid $brand-primary;
      padding-bottom: 2em;
      margin-bottom: 2em;
    }
  }
  &__title {
    color: $brand-primary;
  }
}

.more-info {
  @include padding-bottom(2em,4em);
}

h2.mt-1-mobile {
  @include media("<col-sm") {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    padding-top: 0.5em;
    border-top: 1px solid $gray-lighter;
  }
}

.text-panel {
  margin-bottom: 2.5em;
}

.inpage-links {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  margin-bottom: 3em;
  &__button {
    display: flex;
    background-color: $brand-tertiary;
    border-radius: 5px;
    width: calc(25% - 10px);
    min-height: 63px;
    transition: all 300ms ease;
    margin-left: 5px;
    margin-right: 5px;
    padding: 5px;
    &:hover,
    &:focus {
      color: white;
      background-color: $brand-quaternary;
      border-color: $brand-quaternary;
      text-decoration: none;
    }
    &:nth-child(+n+5) {
      margin-top: 10px;
    }
    // &:not(:nth-child(4n+1)) {
    //   margin-left: 10px;
    // }
  }
  span {
    color: white;
    font-family: $headings-font-family;
    font-size: 1.25em;
    line-height: 1.2;
    text-align: center;
    width: 100%;
    align-self: center;
  }
}

.steps {
  margin-top: 2em;
  &__heading {
    color: $brand-primary;
    padding-bottom: 0.3125em;
    border-bottom: 1px solid $gray-lighter;
  }
}

.quote-block {
  text-align: center;
  @include section-padding(2em,4em);
  font-family: $headings-font-family;
  &__text {
    font-size: 1.5em;
    color: $brand-secondary;
    line-height: 1.1;
    margin-top: 0.5em;
    margin-bottom: 0.666666em;
  }
  &__icon {
    font-size: 2em;
    color: #00B7D3;
  }
  &__author {
    color: $brand-primary;
    font-size: 1.125em;
    margin-bottom: 1.5em;
  }
}

.case-study {
  &__carousel {
    margin-bottom: 20px;
    @include media("≥col-md") {
      margin-bottom: 10px;
    }
    &-cell {
      .flickity-slider {
        transform: translateX(0%) !important;
      }
      img {
      left: 0 !important;
      opacity: 0;
      transition: opacity 1.5s ease-in-out;
      z-index: -1;
        &.is-selected {
          opacity: 1;
          z-index: 0
        }
      }
    }

  }
}
