@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?8c23ez');
  src:  url('../fonts/icomoon.eot?8c23ez#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?8c23ez') format('truetype'),
    url('../fonts/icomoon.woff?8c23ez') format('woff'),
    url('../fonts/icomoon.svg?8c23ez#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-facebook:before {
  content: "\e900";
}
.icon-twitter:before {
  content: "\e901";
}
.icon-instagram:before {
  content: "\e902";
}
.icon-paw:before {
  content: "\e903";
}
.icon-clock:before {
  content: "\e904";
}
.icon-gender:before {
  content: "\e905";
}
.icon-size:before {
  content: "\e906";
}
.icon-dog:before {
  content: "\e907";
}
.icon-cat:before {
  content: "\e908";
}
.icon-children:before {
  content: "\e909";
}
